import { BusinessMap, BusinessMapsApiClient } from '../services/cyber-api';
import { StateApi } from '../services/state-api';

export class CaseUtilities {
    public static async shouldEnableBusinessFeatures(businessMapsApi: BusinessMapsApiClient, company: string): Promise<boolean> {
        // TODO: This is a suboptimal solution as it's a relatively heavy API call. We should implement a better way to
        // determine if the user has access to CaseBook business features. The API could facilitate this through an endpoint.
        const maps = await businessMapsApi.getAll(company);
        return maps.some((x) => x.productName?.toLowerCase().startsWith('business'));
    }
}
