import * as toastr from 'toastr';
import { ToastrTemplates } from './toastr-templates';

export class Toast {
    public static success(message: string, title?: string, overrides?: ToastrOptions): void {
        toastr.success(ToastrTemplates.successMessage(message), title, overrides);
    }

    public static info(message: string, title?: string, overrides?: ToastrOptions): void {
        toastr.info(ToastrTemplates.infoMessage(message), title, overrides);
    }

    public static warning(message: string, title?: string, overrides?: ToastrOptions): void {
        toastr.warning(ToastrTemplates.warningMessage(message), title, overrides);
    }

    public static error(message: string, title?: string, overrides?: ToastrOptions): void {
        toastr.error(ToastrTemplates.errorMessage(message), title, overrides);
    }

    public static threat(message: string, title?: string, overrides?: ToastrOptions): void {
        toastr.error(ToastrTemplates.threatMessage(message), title, overrides);
    }

    public static comment(message: string, title?: string, overrides?: ToastrOptions): void {
        toastr.info(ToastrTemplates.commentMessage(message), title, overrides);
    }

    public static vessel(message: string, title?: string, overrides?: ToastrOptions): void {
        toastr.warning(ToastrTemplates.vesselMessage(message), title, overrides);
    }

    public static investigation(message: string, title?: string, overrides?: ToastrOptions): void {
        toastr.info(ToastrTemplates.investigationMessage(message), title, overrides);
    }

    public static statsApiError(overrides?: ToastrOptions): void {
        toastr.error(ToastrTemplates.errorMessage('Some statistics can currently not be displayed<br><small>The Statistics API appears to be offline</small>'), undefined, {
            preventDuplicates: true,
            ...overrides
        });
    }
}
