import { AlertDetailSchemaActionSteps } from 'services/cyber-api';

export class ActionStepsUtilities {
    public static getTarget(steps: AlertDetailSchemaActionSteps[]): string {
        if (!steps) return null;

        const targetObjects = steps.map((step) => step.object.target);
        let targetObject = targetObjects.length > 0 ? targetObjects[0] : null;

        let target: string;
        if (targetObject)
            // The nesting levels aren't set in stone, so we find the first object that resembles a target, no matter
            // how deeply nested
            do targetObject = Object.values<string>(targetObject)[0];
            while (typeof targetObject === 'object');

        target = targetObject;

        return target && typeof target === 'string'
            ? target
            : null;
    }

    public static getDuration(steps: AlertDetailSchemaActionSteps[]): string {
        if (!steps) return null;

        const args = steps.map((step) => step.object.args);
        const durationObject = args.length > 0 ? args[0] : null;

        let duration: string;
        if (durationObject) {
            // Check if any of the duration object's keys includes the 'duration' string, use that key if so
            // Key could for example be 'sinkhole, duration'
            const keys = Object.keys(durationObject);
            const key = keys.find((k) => k.includes('duration'));
            if (key)
                duration = durationObject[key];
        }

        return duration && typeof duration === 'string'
            ? duration
            : null;
    }

    public static getTargetDisplay(target: string): string {
        // Don't show any $ALERT_URI targets as it's not allowed to be displayed
        if (target === '$ALERT_URI')
            return null;

        return target;
    }

    /**
     * Checks if a target is 'internal', as in, not an external IP/target.
     * @param target Target to check, IP.
     */
    private static isTargetInternal(target: string): boolean {
        // Convert the target (ip) to a number so we can check if it's allowed to be displayed
        const targetAsNumber = this.ipToNumber(target);
        if (isNaN(targetAsNumber)) return false;

        // Checks if the IP is within the 10.*.*.* IP range as this IP range is not allowed to be displayed
        if (targetAsNumber >= this.ipToNumber('10.000.000.000')
            &&
            targetAsNumber <= this.ipToNumber('10.255.255.255')
        )
            return true;

        // Checks if the IP is between 172.16.*.* and 172.31.*.* as this IP range is not allowed to be displayed
        if (targetAsNumber >= this.ipToNumber('172.16.000.000')
            &&
            targetAsNumber <= this.ipToNumber('172.31.255.255')
        )
            return true;

        // Checks it the IP is within the 192.168.*.* IP range as this IP range is not allowed to be displayed
        if (targetAsNumber >= this.ipToNumber('192.168.000.000')
            &&
            targetAsNumber <= this.ipToNumber('192.168.255.255')
        )
            return true;

        return false;
    }

    private static ipToNumber(ip: string): number {
        if (!ip) return null;

        // To convert an IP to a number, but still have it be comparable to all other kinds of IPs, we need to pad each
        // numeric part of the IP to 3 numbers by adding zeroes. This way, the generated IP number can be compared to
        // other generated IP numbers.
        const ipSplit = ip.split('.');
        const paddedSplit = [];
        for (const split of ipSplit)
            paddedSplit.push(
                // Add zeroes to the IP section value to make it three characters long
                split.padStart(3, '0')
            );

        const ipAsNumberString = paddedSplit.join('');
        return Number(ipAsNumberString);
    }
}
