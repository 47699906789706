import * as showdown from 'showdown';

export class ShowdownContainer {
    public static converter = ShowdownContainer.createConverter();

    private static createConverter(): showdown.Converter {
        return new showdown.Converter({
            // Enable hyperlinks without specific markdown syntax
            // note: undoubtly the question will arise to open in new tab, see for this:
            // https://github.com/showdownjs/showdown/issues/337
            simplifiedAutoLink: true,

            // Makes it so that line breaks are always converted to a <br>, without requiring two spaces after a break
            simpleLineBreaks: true,

            // Enables support for conversion of Markdown tables to HTML tables
            tables: true
        });
    }
}
