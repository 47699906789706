export class LocalStorageHelper {
    public static isStorageSupported(): boolean {
        return window.localStorage !== undefined;
    }

    public static set<T>(key: string, object: T): void {
        window.localStorage.setItem(key, JSON.stringify(object));
    }

    public static get<T>(key: string): T {
        const item = window.localStorage.getItem(key);

        // If the localStorage item cannot be parsed, it's likely it was stored un-stringified, i.e. without quotes,
        // therefore we try to parse it with quotes around the value
        try {
            return JSON.parse(item);
        } catch (e) {
            try {
                return JSON.parse(`"${item}"`);
            } catch (error) {
                throw new Error(`Could not parse localStorage item '${key}', please try logging in and out.`);
            }
        }
    }

    public static remove(key: string | LocalStorageKeys): void {
        window.localStorage.removeItem(key);
    }

    public static clear(): void {
        window.localStorage.clear();
    }
}

export enum LocalStorageKeys {
    AccessToken = 'access_token',
    AuthReturnUrl = 'auth_return_url',
    Company = 'company',
    CompanyName = 'company_name',
    Companies = 'companies',
    IdToken = 'id_token',
    ExpiresAt = 'expires_at',
    ThreatListSize = 'threat_list_size',
    LastSignOff = 'last_sign_off'
}
