export class ToastrTemplates {
    public static successMessage = (message: string): string => `
        <div class="d-flex align-items-center">
            <i class="fal fa-check fa-2x fa-fw text-green mr-2"></i>
            <span>${message}</span>
        </div>`

    public static infoMessage = (message: string): string => `
        <div class="d-flex align-items-center">
            <i class="fal fa-info-circle fa-2x fa-fw text-info mr-2"></i>
            <span>${message}</span>
        </div>`

    public static warningMessage = (message: string): string => `
        <div class="d-flex align-items-center">
            <i class="fal fa-exclamation-triangle fa-2x fa-fw text-warning mr-2"></i>
            <span>${message}</span>
        </div>`

    public static errorMessage = (message: string): string => `
        <div class="d-flex align-items-center">
            <i class="fal fa-exclamation-circle fa-2x fa-fw text-red mr-2"></i>
            <span>${message}</span>
        </div>`

    public static threatMessage = (message: string): string => `
        <div class="d-flex align-items-center">
            <span class="fa-layers fa-2x fa-fw mr-2">
                <i class="fal fa-search"></i>
                <i class="far fa-bug" data-fa-transform="shrink-9 up-1.8 left-1.5"></i>
            </span>
            <span>${message}</span>
        </div>`

    public static commentMessage = (message: string): string => `
        <div class="d-flex align-items-center">
            <i class="fal fa-comments-alt fa-2x fa-fw text-info mr-2"></i>
            <span>${message}</span>
        </div>`

    public static vesselMessage = (message: string): string => `
        <div class="d-flex align-items-center">
            <span class="fal fa-ship fa-2x fa-fw mr-2"></span>
            <span>${message}</span>
        </div>`

    public static investigationMessage = (message: string): string => `
        <div class="d-flex align-items-center">
            <i class="fal fa-file-pdf fa-2x fa-fw mr-2"></i>
            <span>${message}</span>
        </div>`
}
