import { AlertXChangeLogCollection } from 'services/cyber-api';
import { ArrayUtilities } from './array-utilities';

export class XChangeUtilities {
    public static parseXChangeBoxLogs(logs: AlertXChangeLogCollection): IThreatXChangeDetails {
        const data = logs.data;

        if (!data || !data.length)
            return null;

        // Filter out any data items that do not contain any items in their localMatches
        const dataWithLocalMatches = data.filter((d) => d.localMatches && d.localMatches.length > 0);

        // Flatten all local matches into one single-dimensional array
        const allLocalMatches = ArrayUtilities.flattenArrayOfArrays(dataWithLocalMatches.map((d) => d.localMatches));

        // Find all machines in the localMatches and make sure they are unique
        const uniqueMachines = ArrayUtilities.unique(
            allLocalMatches.map((lm) => `${lm.netbios || ''}|${lm.mac || ''}`)
        );

        // Convert the uniqueMachines strings back to actual objects
        const machines = uniqueMachines.map((um) => {
            const split = um.split('|');
            return {
                machineName: split[0],
                macAddress: split[1]
            };
        });

        // Extract all usernames from the localMatches
        const usernames = ArrayUtilities.unique(allLocalMatches.map((lm) => lm.login).filter((value) => value));
        const users = usernames.map((username) => {
            return {
                username,
                group: null // Group will have to be enriched through other means
            }
        })

        // Extract all lans from the localMatches
        const lans = ArrayUtilities.unique(allLocalMatches.map((lm) => lm.lan).filter((value) => value));

        // Retrieve the local IP
        const localIps = allLocalMatches.map((lm) => lm.src_ip).filter((value) => value);
        const localIp = localIps.length > 0 ? localIps[0] : null;

        return {
            machines,
            users,
            lans,
            localIp
        };
    }
}

export interface IThreatXChangeDetails {
    users: IThreatXChangeDetailsUser[];
    machines: IThreatXChangeDetailsMachine[];
    lans: string[];
    localIp: string;
}

export interface IThreatXChangeDetailsUser {
    username: string;
    group: string;
}

export interface IThreatXChangeDetailsMachine {
    machineName: string;
    macAddress: string;
}
