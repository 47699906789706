export class ArrayUtilities {
    /**
     * Groups a collection by a property to a Map type.
     * @param collection The collection to group.
     * @param groupKeyResolver Contains the property to group by, computed from a function.
     * @returns Map key-value pair collection with given property as key and elements with that property as values.
     */
    public static groupBy<K, T>(collection: T[], groupKeyResolver: (item: T) => K): Map<K, T[]> {
        return collection.reduce(
            (map: Map<K, T[]>, item: T) => {
                // Get the group name from the groupKeyResolver function, this will be the key of the group in the array.
                const key = groupKeyResolver(item);

                if (map.has(key))
                    map.get(key).push(item);
                else
                    map.set(key, [item]);

                return map;
            },
            new Map<K, T[]>() // Create a new Map collection which will serve as the initial collection value.
        );
    }

    public static flattenArrayOfArrays<T>(array: T[][]): T[] {
        return [].concat.apply([], array);
    }

    public static unique<T>(array: T[], uniqueResolver?: (item: T) => string | number): T[] {
        if (!array || array.length === 0) return array;

        // If there is no uniqueResolver function, process them to be unique by value
        if (typeof (uniqueResolver) !== 'function')
            return ArrayUtilities.uniqueValues(array);

        // Find all the values that should be unique
        const uniqueValues = ArrayUtilities.uniqueValues(
            array.map((item) => uniqueResolver(item))
        );

        // Get one item from the original array for every unique value to construct an array of unique items
        return uniqueValues.map((uniqueValue) =>
            array.find((item) => uniqueResolver(item) === uniqueValue)
        );
    }

    private static uniqueValues<T>(array: T[]): T[] {
        return [...new Set(array)];
    }
}
