import { ThemeColors } from 'resources/theme/theme-colors';

export class Utilities {
    public static getRiskCategory(risk: number): string {
        if (risk >= 66)
            return 'High';
        else if (risk >= 33)
            return 'Medium';
        else if (risk > 0)
            return 'Low';
        else if (risk === 0 || risk === null)
            return 'No Risk';
    }

    public static getRiskCategoryRange(category: string): number[] {
        switch (category.toLowerCase()) {
            case 'high':
                return [66, 100];
            case 'medium':
                return [33, 65.9];
            case 'low':
                return [0.1, 32.9];
            case 'none':
            case 'norisk':
            case 'unaffected':
            case 'null':
                return [0, 0];
        }
    }

    public static getRiskColor(risk: number): string {
        if (risk > 66)
            return ThemeColors.Red;

        if (risk > 33)
            return ThemeColors.Orange;

        if (risk > 0)
            return ThemeColors.Yellow;

        return ThemeColors.LightGrey;
    }

    public static getUrgencyDisplay(urgency: number): string {
        if (urgency > 66)
            return 'Major';

        if (urgency > 33)
            return 'Moderate';

        if (urgency > 0)
            return 'Minor';

        return 'None';
    }

    public static getUrgencyColor(urgency: number): string {
        if (urgency > 66)
            return ThemeColors.Red;

        if (urgency > 33)
            return ThemeColors.Orange;

        if (urgency > 0)
            return ThemeColors.Yellow;

        return ThemeColors.LightGrey;
    }

    public static getUrgencyColorByName(urgency: string): string {
        if (urgency === 'major')
            return ThemeColors.Red;

        if (urgency === 'moderate')
            return ThemeColors.Orange;

        if (urgency === 'minor')
            return ThemeColors.Yellow;

        return ThemeColors.LightGrey;
    }

    public static getUrgencyColorClass(urgency: number): string {
        if (urgency > 66)
            return 'red';

        if (urgency > 33)
            return 'orange';

        if (urgency > 0)
            return 'yellow';

        return 'grey-light';
    }

    public static formatNumber(value: number, maxDigits: number = 0, minDigits: number = 0, locale: string = 'nl-NL', style: string = 'decimal'): string {
        if (value === undefined || value === null) return null;

        // see also: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
        const formatter = new Intl.NumberFormat(locale, {
            style,
            minimumFractionDigits: minDigits,
            maximumFractionDigits: maxDigits
        });

        return formatter.format(value);
    }

    public static formatBytes(bytes: number, decimals: number = 2, appendLabel: boolean = true): string {
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        let value: string;
        if (!bytes) {
            value = '0';

            if (appendLabel)
                return `${value} ${sizes[0]}`;
            return value;
        }

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        value = parseFloat((bytes / Math.pow(k, i)).toFixed(dm)).toString();

        if (appendLabel)
            value = `${value} ${sizes[i]}`;

        return value;
    }

    public static parseSekoiaTtSiteId(value: string): number {
        return parseInt(value
            .replace('Vessel ', '')
            .replace('Vassel - ', '')
            .replace('Vesssel ', '')
            .replace(' ', '')
        );
    }

    /**
     * Copies the given value to the clipboard
     * @param value Value to copy to clipboard
     * @param hostElement Element to host the temporary input under that is required for copying to clipboard, defaults
     * to document.body
     */
    public static copyToClipboard(value: string, hostElement: HTMLElement = document.body): void {
        // Create a temporary input through which we can copy value to the clipboard
        const tempInput = document.createElement('input');

        // Assign the value to the temporary input
        tempInput.value = value;

        // Add the temporary input as a child to the host element
        hostElement.appendChild(tempInput);

        // Copy to clipboard
        tempInput.select();
        document.execCommand('copy');

        // Finally, remove the temporary input from the host element
        hostElement.removeChild(tempInput);
    }
}
